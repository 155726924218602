<template>
    <div class="layout">
        <Header
            :menus="menus"
            :title="'文档中心'"
            :subTitle="'行业主板'"
        ></Header>

        <div class="page-section">
            <div class="container container-menu">
                <div class="container-wrap menu-wrap">
                    <ul class="menu-content">
                        <li class="active">
                            文档
                        </li>

                        <li>FAQ</li>
                    </ul>
                </div>
            </div>
            <div class="container container-detail">
                <div class="container-wrap detail-wrap">
                    <ul class="detail-menu">
                        <li>
                            <div class="detail-menu-main">
                                <img src="../assets/icon/assets-1.png" alt="" />
                                <span>前言</span>
                            </div>
                        </li>
                        <li>
                            <div class="detail-menu-main">
                                <img src="../assets/icon/assets-2.png" alt="" />
                                <span>快速入门</span>
                            </div>
                            <ul class="detail-menu-sub">
                                <li>
                                    架构简介
                                </li>
                                <li>
                                    固件版本说明
                                </li>
                            </ul>
                        </li>
                        <li>
                            <div class="detail-menu-main">
                                <img src="../assets/icon/assets-3.png" alt="" />
                                <span>接口文档</span>
                            </div>
                        </li>
                        <li>
                            <div class="detail-menu-main">
                                <img src="../assets/icon/assets-4.png" alt="" />
                                <span>版本查询</span>
                            </div>

                            <ul class="detail-menu-sub">
                                <li>
                                    V1.5.0版本文档
                                </li>
                            </ul>
                        </li>
                    </ul>
                    <div class="detail-content">
                        <div class="detail-content-bread">
                            <span
                                ><a href="">文档中心</a>>
                                <a href="">接口文档</a> >
                                <a href=""> 版本查询</a></span
                            >
                        </div>
                        <div class="detail-content-version">
                            <div class="detail-content-version-title">
                                版本查询
                            </div>
                            <div class="detail-content-version-input">
                                <input
                                    type="text"
                                    class="detail-content-version-android"
                                    placeholder="安卓版本"
                                />
                            </div>
                            <div class="detail-content-version-input">
                                <input
                                    type="text"
                                    class="detail-content-version-system"
                                    placeholder="网飞系统服务版本号"
                                />
                            </div>
                            <div class="detail-content-version-search">
                                查询
                            </div>
                        </div>
                        <div class="detail-content-version">
                            <div class="detail-content-version-title">
                                查询结果
                            </div>
                            <div class="detail-content-version-subTitle">
                                V1.5.0版本文档
                            </div>

                            <div class="detail-content-version-search">
                                点击查看
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="container container-experience">
                <div class="container-wrap">
                    <div class="experience-title">
                        免费试用名额等你来拿，赶紧注册吧！
                    </div>
                    <ul class="experience-list">
                        <li>
                            <div class="experience-dot"></div>
                            行业主板
                        </li>
                        <li>
                            <div class="experience-dot"></div>
                            安卓智慧屏
                        </li>
                        <li>
                            <div class="experience-dot"></div>
                            WIF-OS
                        </li>
                    </ul>
                    <div class="experience-btn">
                        立即体验
                    </div>
                </div>
            </div>
        </div>
        <DocFooter />
    </div>
</template>

<script>
import { Header, DocFooter } from "@components";

export default {
    name: "App",
    components: {
        Header,
        DocFooter
    },
    data() {
        return {
            menus: [
                {
                    text: "Wif.ink",
                    id: "app"
                },
                {
                    text: "社区",
                    id: "about"
                }
            ]
        };
    }
};
</script>
